import React, { useCallback, useEffect, useRef, useState } from "react";
import GoogleFontLoader from "react-google-font-loader";
import { Provider } from "react-redux";
import { Navigate, Route, Routes as RouterSwitch, useLocation, useNavigate } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import { WithStyles, withStyles } from "@mui/styles";

import Configuration from "./admin/models/Configuration";
import Admin from "./admin/pages/Admin";
import LoadingSpinner from "./components/LoadingSpinner";
import { isSupportedGoogleFont } from "./helpers/FontHelper";
import { consentRedirectByErrorResponseStatus } from "./helpers/RedirectHelper";
import Consent from "./models/Consent";
import CustomerDetailsData from "./models/CustomerDetailsData";
import DataHolder from "./models/DataHolder";
import GeneralSettings from "./models/GeneralSettings";
import PreConsent from "./models/PreConsent";
import PreConsentData from "./models/PreConsentData";
import ValidateTokenResponse from "./models/ValidateTokenResponse";
import OpenBankingPlatformAPI from "./openbankingplatform/OpenBankingPlatformAPI";
import OpenBankingPlatformMockApi from "./openbankingplatform/OpenBankingPlatformMockApi";
import BankFeedsRequest from "./pages/BankFeedsRequest";
import CDRValueProposition from "./pages/CDRValueProposition";
import ConsentApiAuthRedirect from "./pages/ConsentApiAuthRedirect";
import ConsentDetails from "./pages/ConsentDetails";
import ConsentHistory from "./pages/ConsentHistory";
import CustomerDetailsInputs from "./pages/CustomerDetailsInputs";
import Dashboard from "./pages/Dashboard";
import DataHolderConnectConfirmation from "./pages/DataHolderConnectConfirmation";
import DataHolderSelection from "./pages/DataHolderSelection";
import DataRequest from "./pages/DataRequest";
import Error from "./pages/Error";
import MultiBankSelectionList from "./pages/MultiBankSelectionList";
import MultibankSuccess from "./pages/MultibankSuccess";
import PreviousDataDeleteConfirmation from "./pages/PreviousDataDeleteConfirmation";
import ProductValueProposition from "./pages/ProductValueProposition";
import SessionExpired from "./pages/SessionExpired";
import SessionFinished from "./pages/SessionFinished";
import Success from "./pages/Success";
import Unauthorised from "./pages/Unauthorised";
import WithdrawConsent from "./pages/WithdrawConsent";
import WithdrawConsentConfirmation from "./pages/WithdrawConsentConfirmation";
import WithdrawConsentSuccess from "./pages/WithdrawConsentSuccess";
import store from "./store/store";
import { defaultTheme, styles, ThemeId } from "./Theme";
import ThemeBuilder from "./ThemeBuilder";
import NotFound from "./pages/NotFound";
import Insight from "./models/Insight";
import DeveloperTools from "./models/DeveloperTools";
import DashboardSettings from "./models/DashboardSettings";
import TrustedAdvisers from "./pages/TrustedAdvisers";
import DataDelivery from "./pages/DataDelivery";
//import BrokerSignUpHomePage from "./pages/signup/BrokerSignUpHomePage";


interface AppState {
    title: string;
    dataHolderCurrentSelection?: DataHolder;
    haveAuthorisationWith: Map<string, boolean>;
    theme: ThemeId;
    validateTokenResponse?: ValidateTokenResponse;
    currentWithdrawConsentDataDeleteOption?: boolean;
    loadedPreConsentConfig?: PreConsent;
    loadedGeneralSettingsConfig?: GeneralSettings;
    loadedDashboardSettingsConfig?: DashboardSettings;
    loadedDeveloperToolsConfig?: DeveloperTools;
    loadedConsentConfig?: Consent;
    loadedInsightConfig?: Insight;
    loadedDeveloperTools?: DeveloperTools;
    loadedThemeOptions: Partial<Theme> | ((outerTheme: Theme) => Theme);
    existingPreConsentData?: PreConsentData | undefined;
    isLoading: boolean;
    customerDetails?: CustomerDetailsData;
    customerDetailsRequired?: boolean;
    apiError?: boolean;
    sessionExpired: boolean;
    principalLogoUrl: string;
    accreditationIconUrl?: string;
    headerBgImageUrl?: string;
    pvpFeatureKeyVisualImageUrl?: string;
    pvpFeature1ImageUrl?: string;
    pvpFeature2ImageUrl?: string;
    pvpFeature3ImageUrl?: string;
    cdrSupportingImageUrl?: string;
    cdrFeature1ImageUrl?: string;
    cdrFeature2ImageUrl?: string;
    cdrFeature3ImageUrl?: string;
    isUpdateConsentFromDashboard: boolean;
    brokerCode: string;
}

function App(props: WithStyles<typeof styles>) {
    const [state, setState] = useState<AppState>({
        title: "",
        /*customerDetailsRequired: true,*/
        haveAuthorisationWith: new Map<string, boolean>(),
        theme: "Default",
        isLoading: true,
        loadedThemeOptions: createTheme(defaultTheme),
        sessionExpired: false,
        principalLogoUrl: "",
        accreditationIconUrl: "",
        headerBgImageUrl: "",
        isUpdateConsentFromDashboard: false,
        brokerCode: ""
    })

    const location = useLocation();

    useEffect(() => {
        const updatePageTitle = (newPageTitle: string) => {
            setState(state => ({
                ...state,
                title: newPageTitle,
            }))
        }

        if (location.pathname.includes("preconsent")) {
            updatePageTitle("");
        } else {
            if (location.pathname.includes("consent")) {
                updatePageTitle("Consent");
            } else {
                updatePageTitle("");
            }
        }
        window.scrollTo(0, 0);
        window.parent.postMessage({ action: "OB_SCROLL_TO_TOP" }, "*");
    }, [location.pathname]);

    useEffect(() => {
        // @ts-ignore
        if (location.state && location.state.sessionExpired) {
            setState(state => ({
                ...state,
                sessionExpired: true
            }));
        }
        // eslint-disable-next-line
    }, [location]);

    const handleDataHolderSelectionChange = (newSelection: DataHolder, loadedPreConsentData?: PreConsentData) => {
        setState(state => ({
            ...state,
            dataHolderCurrentSelection: { ...newSelection },
            existingPreConsentData: loadedPreConsentData,
            currentWithdrawConsentDataDeleteOption: loadedPreConsentData?.delete_my_data_after_used === 1
        }));
    }

    const handleAuthorisationAccept = (dataHolder: DataHolder) => {
        const newHaveAuthorisationWith = new Map(state.haveAuthorisationWith);
        newHaveAuthorisationWith.set(dataHolder.id, true);
        setState(state => ({
            ...state,
            haveAuthorisationWith: newHaveAuthorisationWith,
        }));
    }

    const handleWithdrawConsentDeleteDataOption = (newOption: boolean) => {
        setState(state => ({
            ...state,
            currentWithdrawConsentDataDeleteOption: newOption,
        }));
    }

    const handleConsentButtonOnDataRequestScreen = (newPreConsentData?: PreConsentData) => {
        setState(state => ({
            ...state,
            existingPreConsentData: newPreConsentData,
        }));
    }

    const handleIUnderstandButtonOnPreviousDataDeleteConfirmScreen = (newPreConsentData?: PreConsentData) => {
        setState(state => ({
            ...state,
            existingPreConsentData: newPreConsentData,
        }));
    }

    const handleCallBackFromDataHolder = (storedDataHolder: DataHolder) => {
        setState(state => ({
            ...state,
            dataHolderCurrentSelection: { ...storedDataHolder },
        }));
    }


    const navigate = useNavigate();

    const brokerSignUpBasePath = '/';

    const consentUiBasePath = process.env.REACT_APP_BASE_PATH ?? '/consentui';
    const trustedAdvisersBasePath = process.env.REACT_APP_TRUSTED_ADVISERS_BASE_PATH ?? '/trusted-advisers';

    const adminBasePath = process.env.REACT_APP_ADMIN_BASE_PATH ?? '/admin';
    const apiAuthBasePath = process.env.REACT_APP_API_AUTH_PATH ?? '/api/auth';
    const dashboardPath = process.env.REACT_APP_DASHBOARD_BASE_PATH ?? '/dashboardui';
    const dataDeliveryPath = '/app/v1/data-delivery';

    const trustedAdvisersDashboardPath = process.env.REACT_APP_TRUSTED_ADVISER_DASHBOARD_BASE_PATH ?? '/trusted-advisers/dashboardui';

    //@ToDo : Need to find a better way to identify the url with token parameter
    //This is a temporary adjustment
    const token: any = location.pathname.split("/").pop() !== 'preconsent' ? location.pathname.split("/").pop() : sessionStorage.getItem('tokenString');
    //@ToDo : Need to find a better way to identify the token from the url
    //This is a temporary adjustment
    const hasTokenUrl: boolean = token?.split('.').length === 3 ? true : false;
    const storedToken = hasTokenUrl ? token : sessionStorage.getItem('tokenString');
    const apiUrl = process.env.REACT_APP_API_URL ?? '';
    const trustedAdviserApiUrl = process.env.REACT_APP_TRUSTED_ADVISERS_API_URL ?? apiUrl;
    if (!apiUrl) {
        //throw Error("REACT_APP_API_URL is not defined in the configuration");
    }
    const obApi = new OpenBankingPlatformAPI(new URL(apiUrl), storedToken);
    const mockApi = new OpenBankingPlatformMockApi();
    const obTrustedAdvisersApi = new OpenBankingPlatformAPI(new URL(trustedAdviserApiUrl), storedToken);

    const isTrustedAdviserSession: boolean = sessionStorage.getItem('isTrustedAdviserSession') === 'true' ?? false;

    //eslint-disable-next-line
    const validateToken = useCallback(obApi.validateToken, []);
    //eslint-disable-next-line
    const getUser = useCallback(obApi.getUser, []);
    //eslint-disable-next-line
    const getCustomerDetails = useCallback(obApi.getCustomerDetails, []);
    //eslint-disable-next-line
    const getCurrentConfiguration = useCallback(obApi.getCurrentConfiguration, []);
    //eslint-disable-next-line
    const changeSelectedPrincipal = useCallback(obApi.changeSelectedPrincipal, []);
    //eslint-disable-next-line
    const validatePostConsentSession = useCallback(obApi.validatePostConsentSession, []);

    const [currentPrincipal, setCurrentPrincipal] = useState<number>();
    const loadPrincipalConfiguration = async (principal_id?: number, skip_change?: boolean) => {
        if (principal_id === undefined) {
            principal_id = 0;
        }

        if (principal_id === currentPrincipal) {
            return;
        }

        if (location.pathname.startsWith(apiAuthBasePath)) {
            setState(state => ({
                ...state,
                isLoading: false,
                apiError: false
            }));
            return;
        }

        let loadedConfigurations: Configuration;
        if (skip_change) {
            loadedConfigurations = await getCurrentConfiguration();
        } else {
            loadedConfigurations = await changeSelectedPrincipal(principal_id);
        }

        if (loadedConfigurations.principal_id === currentPrincipal) {
            return;
        }

        const customerDetailsCheck = await getCustomerDetails();
        setCurrentPrincipal(loadedConfigurations.principal_id);

        if (typeof Promise.allSettled !== 'function') {
            Promise.allSettled = function(promises: any) {
                return Promise.all(promises.map((promise: any) => {
                    return promise.then((value: any) => ({
                        status: 'fulfilled',
                        value: value
                    }))
                    .catch((reason: any) => ({
                        status: 'Promise.allSettled: rejected',
                        reason: reason
                    }));
                }));
            };
        }

        const [
            principalLogoUrl,
            accreditationIconUrl,
            headerBgImageUrl,
            pvpFeatureKeyVisualImageUrl,
            cdrSupportingImageUrl,
            pvpFeature1ImageUrl,
            pvpFeature2ImageUrl,
            pvpFeature3ImageUrl,
            cdrFeature1ImageUrl,
            cdrFeature2ImageUrl,
            cdrFeature3ImageUrl,
        ] = await Promise.allSettled([
            getPrincipalHeaderLogoSrc(loadedConfigurations.generalSettings),
            getAccreditationIconSrc(loadedConfigurations.generalSettings),
            getHeaderBgImageSrc(loadedConfigurations.generalSettings),
            getPvrImageSrcUrls(loadedConfigurations, 0),
            getCdrImageSrcUrls(loadedConfigurations, 0),
            ...Array.from({ length: 3 }, (_, i) =>
                i < loadedConfigurations.preConsent.pvpNumberOfFeatures
                ? getPvrImageSrcUrls(loadedConfigurations, i + 1)
                : Promise.resolve('')
            ),
            ...Array.from({ length: 3 }, (_, i) =>
                i < loadedConfigurations.preConsent.cdrNumberOfFeatures
                ? getCdrImageSrcUrls(loadedConfigurations, i + 1)
                : Promise.resolve('')
            ),
        ]).then(result => {
            return result.map(item => item.status === 'fulfilled' ? item.value : '')
        })
          .catch(_ => {
            return new Array(11).fill('');
        });

        const loadedTheme = ThemeBuilder({
            //@ToDo : It is better if we have an option to choose which theme to be applied from admin
            theme: 'Custom',
            generalSettings: loadedConfigurations.generalSettings,
        });

        setState(state => ({
            ...state,

            principalLogoUrl,
            accreditationIconUrl,
            headerBgImageUrl,
            pvpFeatureKeyVisualImageUrl,
            cdrSupportingImageUrl,
            pvpFeature1ImageUrl,
            pvpFeature2ImageUrl,
            pvpFeature3ImageUrl,
            cdrFeature1ImageUrl,
            cdrFeature2ImageUrl,
            cdrFeature3ImageUrl,

            loadedPreConsentConfig: loadedConfigurations.preConsent,
            loadedGeneralSettingsConfig: loadedConfigurations.generalSettings,
            loadedDashboardSettingsConfig: loadedConfigurations.dashboard,
            loadedConsentConfig: loadedConfigurations.consent,
            loadedInsightConfig: loadedConfigurations.insight,
            loadedDeveloperTools: loadedConfigurations.developerTools,
            isUpdateConsentFromDashboard: loadedConfigurations.isConsentUpdateFromDashboard,
            loadedThemeOptions: loadedTheme,
            customerDetails: customerDetailsCheck.customerDetails,
            customerDetailsRequired: customerDetailsCheck.customerDetails !== null ? false : true,
            isLoading: false,
            apiError: false
        }));
    }

    const setTheme = (theme: Theme) => {
        setState(state => ({
            ...state,
            loadedThemeOptions: theme
        }));
    }

    const shouldRedirectToMockErrorForDev = (developerTools?: DeveloperTools) => {
        if (developerTools) {
            if (developerTools.incCannotLoadConsumerConsentFlow) {
                navigate("/unauthorised", {
                    state: {
                        errorCode: 10011,
                        errorText: 'Unauthorised access',
                    }
                });

                return true;
            }

            if (developerTools && developerTools.incConsumerConsentSessionTimedOut) {
                navigate("/session-expired", {
                    state: {
                        errorCode: 10008,
                        errorText: 'Session expired',
                    }
                });

                return true;
            }
        }

        return false;
    }

    useEffect(() => {
        if (location.pathname === brokerSignUpBasePath) {
            return;
        }

        if (!location.pathname.startsWith(adminBasePath) && !location.pathname.startsWith(trustedAdvisersBasePath)) {
            (async (loadedDeveloperTools) => {
                try {
                    // If the URL contains the token (we assume this is for the consent flow initialise link
                    // when the application is loaded for the first time) then make an API request to the
                    // backend to validate token and acquire the secure session token cookie in the process
                    if (hasTokenUrl) {
                        try {
                            // If the developer tool configuration is enabled to simulate session timeout and/or
                            // consent flow token expiry error then just redirect to the error page before loading
                            // anything else
                            if (shouldRedirectToMockErrorForDev(loadedDeveloperTools)) {
                                return;
                            }

                            if (state.apiError || state.sessionExpired) {
                                return;
                            }

                            const validateTokenResponse = await validateToken();
                            setState(state => ({
                                ...state,
                                validateTokenResponse: validateTokenResponse,
                            }));
                        } catch (error) {
                            setState(state => ({
                                ...state,
                                isLoading: false,
                                apiError: true
                            }));
                            consentRedirectByErrorResponseStatus(navigate, (error as any).response);
                        }
                    }

                    // Check that if the current route is the success screen after consent has been provide
                    if (location.pathname.includes('/consent/success')) {
                        const postConsentSessionDetails = await validatePostConsentSession();
                        if (postConsentSessionDetails.isTrustedAdvisersSession && postConsentSessionDetails.brokerCode) {
                            const currentFragment = location.hash;

                            setState(state => ({
                                ...state,
                                brokerCode: postConsentSessionDetails.brokerCode,
                            }))

                            // If we detect that we are current in a trusted advisers session then
                            // make we redirect the consumer to the correct route for it
                            navigate(trustedAdvisersBasePath + `/consentui/consent/success${currentFragment}`, {});
                        } else {
                            // Otherwise load all the principal style configurations (which determines the 'look-and-feel' of UI)
                            // for the standard consent flow
                            await loadPrincipalConfiguration(0, location.pathname.includes('/consent/success'));
                        }
                    } else {
                        if (state.apiError || state.sessionExpired) {
                            return;
                        }
                        // Otherwise load all the principal style configurations (which determines the 'look-and-feel' of UI)
                        // for the standard consent flow
                        await loadPrincipalConfiguration(0, location.pathname.includes('/consent/success'));
                    }
                } catch (error) {
                    setState(state => ({
                        ...state,
                        isLoading: false,
                        apiError: true,
                    }))
                    consentRedirectByErrorResponseStatus(navigate, (error as any).response);
                }
            })(state.loadedDeveloperTools);
        }
        //eslint-disable-next-line
    }, []);

    const isURL = (str: string) => {
        if (str.startsWith('/')) return true;
        try {
            const newUrl = new URL(str);
            return newUrl.protocol === 'http:' || newUrl.protocol === 'https:' || newUrl.protocol === 'blob:';
        } catch (_) {
            return false;
        }
    }

    //eslint-disable-next-line
    const getFile = useCallback(obApi.getFile, []);
    const getPrincipalHeaderLogoSrc = async (generalSettings: GeneralSettings) => {
        if (!generalSettings.cdrUploadLogo || generalSettings.cdrUploadLogo === '') {
            return null;
        }
        if (isURL(generalSettings.cdrUploadLogo)) {
            return generalSettings.cdrUploadLogo;
        }
        try {
            const logoUrl = `/principal-logos/${generalSettings.principalId}/${generalSettings.cdrUploadLogo}`;
            const data = await getFile(logoUrl, true);
            if (data) {
                const url = URL.createObjectURL(data);
                return url;
            }
            else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    const getHeaderBgImageSrc = async (generalSettings: GeneralSettings) => {
        if (!generalSettings.headerBackgroundImage || generalSettings.headerBackgroundImage === '') return null;
        if (isURL(generalSettings.headerBackgroundImage)) return generalSettings.headerBackgroundImage;
        try {
            const headerBgImageUrl = `/header-backgrounds/${generalSettings.principalId}/${generalSettings.headerBackgroundImage}`;
            const data = await getFile(headerBgImageUrl, true);
            if (data) {
                const url = URL.createObjectURL(data);
                return url;
            }
            else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    const getAccreditationIconSrc = async (generalSettings: GeneralSettings) => {
        if (!generalSettings.accreditationUploadIcon || generalSettings.accreditationUploadIcon === '') return null;
        if (isURL(generalSettings.accreditationUploadIcon)) return generalSettings.accreditationUploadIcon;
        try {
            const iconUrl = `/accreditation-icons/${generalSettings.principalId}/${generalSettings.accreditationUploadIcon}`;
            const data = await getFile(iconUrl, true);
            if (data) {
                const url = URL.createObjectURL(data);
                return url;
            }
            else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    const getPvrImageSrcUrls = async (config: Configuration, i: number) => {
        const pvpFeatureImage = getPvpFeatureImageAttribute(config, i);
        if (!pvpFeatureImage || pvpFeatureImage === '') return null;
        if (isURL(pvpFeatureImage)) return pvpFeatureImage;
        try {
            var pvpFeatureImageUrl = `/principal-config-images/pvp-feature-image-${i}/${config.generalSettings.principalId}/${pvpFeatureImage}`;
            if (i === 0) {
                pvpFeatureImageUrl = `/principal-config-images/pvp-key-visual-image/${config.generalSettings.principalId}/${pvpFeatureImage}`;
            }

            const data = await getFile(pvpFeatureImageUrl, true);
            if (data) {
                const url = URL.createObjectURL(data);
                return url;
            }
            else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    const getPvpFeatureImageAttribute = (config: Configuration, i: number) => {
        switch (i) {
            case 0:
                return config.preConsent.pvpKeyVisualFileName;
            case 1:
                return config.preConsent.pvpFeature1Image;
            case 2:
                return config.preConsent.pvpFeature2Image;
            case 3:
                return config.preConsent.pvpFeature3Image;
        }
    }

    const getCdrImageSrcUrls = async (config: Configuration, i: number) => {
        const CdrFeatureImage = getCdrFeatureImageAttribute(config, i);
        if (!CdrFeatureImage || CdrFeatureImage === '') return null;
        if (isURL(CdrFeatureImage)) return CdrFeatureImage;
        try {
            var cdrFeatureImageUrl = `/principal-config-images/cdr-feature-image-${i}/${config.generalSettings.principalId}/${CdrFeatureImage}`;
            if (i === 0) {
                cdrFeatureImageUrl = `/principal-config-images/cdr-supporting-image/${config.generalSettings.principalId}/${CdrFeatureImage}`;
            }

            const data = await getFile(cdrFeatureImageUrl, true);
            if (data) {
                const url = URL.createObjectURL(data);
                return url;
            }
            else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    const getCdrFeatureImageAttribute = (config: Configuration, i: number) => {
        switch (i) {
            case 0:
                return config.preConsent.cdrSupportingImageFileName;
            case 1:
                return config.preConsent.cdrFeature1Image;
            case 2:
                return config.preConsent.cdrFeature2Image;
            case 3:
                return config.preConsent.cdrFeature3Image;
        }
    }

    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            let path = location.pathname;
            // TODO: ridiculous code struture!!
            if (!path.includes('preconsent') &&
                !path.includes('/consent/success') &&
                !path.startsWith(dashboardPath) &&
                !path.startsWith(adminBasePath) &&
                !path.startsWith(trustedAdvisersBasePath) &&
                path !== brokerSignUpBasePath) {
                sessionStorage.clear();
            }
            firstUpdate.current = false;
        }
    }, [location.pathname, adminBasePath, dashboardPath, trustedAdvisersBasePath]);


    useEffect(() => {
        if (state.customerDetails) {
            sessionStorage.setItem('customer', JSON.stringify(state.customerDetails));
        }
    }, [state.customerDetails]);


    const connectAnotherDataholderPath = consentUiBasePath + "/consent/data-holder-selection";

    // If this any other page other than the Admin portal .....
    //TODO: stupid setup in app.tsx like this, needs a code refactor!
    if (!location.pathname.includes(adminBasePath) && !location.pathname.includes(trustedAdvisersBasePath) && location.pathname !== brokerSignUpBasePath && !location.pathname.includes(dataDeliveryPath)) {
        //Make sure we maintain a loading state until all data has been fetched
        //so that we can perform the correct route directions based on the fetched data
        if (state.isLoading) {
            return <LoadingSpinner position={"fixed"} overlay />
        }
    }

    const isMultiBank = state.loadedGeneralSettingsConfig?.multibankEnabled && !state.loadedGeneralSettingsConfig.provideConsentViaApi;
    const isConsentUpdateFromDashboard = state.isUpdateConsentFromDashboard;

    return (
        <>
            {(state.loadedGeneralSettingsConfig &&
                state.loadedGeneralSettingsConfig.headingFontFamily &&
                isSupportedGoogleFont(state.loadedGeneralSettingsConfig.headingFontFamily)) &&
                <GoogleFontLoader fonts={[
                    {
                        font: state.loadedGeneralSettingsConfig.headingFontFamily,
                        weights: [400, "400i", 700, "700i"]
                    }
                ]} />
            }
            {(state.loadedGeneralSettingsConfig &&
                state.loadedGeneralSettingsConfig.bodyTextFontFamily &&
                state.loadedGeneralSettingsConfig.bodyTextFontFamily !== state.loadedGeneralSettingsConfig.headingFontFamily &&
                isSupportedGoogleFont(state.loadedGeneralSettingsConfig.bodyTextFontFamily)) &&
                <GoogleFontLoader fonts={[
                    {
                        font: state.loadedGeneralSettingsConfig.bodyTextFontFamily,
                        weights: [400, "400i", 700, "700i"]
                    }
                ]} />
            }
            {(state.loadedGeneralSettingsConfig &&
                state.loadedGeneralSettingsConfig.footerFontFamily &&
                isSupportedGoogleFont(state.loadedGeneralSettingsConfig.footerFontFamily)) &&
                <GoogleFontLoader fonts={[
                    {
                        font: state.loadedGeneralSettingsConfig.footerFontFamily,
                        weights: [400, "400i", 700, "700i"]
                    }
                ]} />
            }
            <Provider store={store}>
                <ThemeProvider<Theme> theme={state.loadedThemeOptions}>
                    <CssBaseline />
                    <RouterSwitch>
                        <Route path={adminBasePath + "/*"} element={
                            <Admin
                                basePath={adminBasePath}
                                api={mockApi}
                            />
                        } />

                        {/*
                        // TODO: We can perhaps re-enable and re-purpose the page under the base URL to show the Open Banking product page at some point in the future?
                        <Route path={"/"} element={
                           <BrokerSignUpHomePage
                               basePath={brokerSignUpBasePath}
                           />
                        } />
                        */}

                        <Route path={trustedAdvisersBasePath + "/*"} element={
                            <TrustedAdvisers
                                basePath={trustedAdvisersBasePath}
                                obApi={obTrustedAdvisersApi}
                                getPrincipalHeaderLogoSrc={getPrincipalHeaderLogoSrc}
                                getAccreditationIconSrc={getAccreditationIconSrc}
                                getHeaderBgImageSrc={getHeaderBgImageSrc}
                                getPvrImageSrcUrls={getPvrImageSrcUrls}
                                getCdrImageSrcUrls={getCdrImageSrcUrls}
                                handleDataHolderSelectionChange={handleDataHolderSelectionChange}
                                handleWithdrawConsentDeleteDataOption={handleWithdrawConsentDeleteDataOption}
                                handleConsentButtonOnDataRequestScreen={handleConsentButtonOnDataRequestScreen}
                                dashboardPath={trustedAdvisersDashboardPath}
                                isMultiBank={isMultiBank}
                                currentDeleteOption={state.currentWithdrawConsentDataDeleteOption}
                                consentExists={state.existingPreConsentData?.consent_exists}
                                loadedPreConsentData={state.existingPreConsentData}
                                currentDataHolderSelection={state.dataHolderCurrentSelection}
                                onIUnderstandButtonClick={handleIUnderstandButtonOnPreviousDataDeleteConfirmScreen}
                                onAuthorisationAccept={handleAuthorisationAccept}
                                handleCallBackFromDataHolder={handleCallBackFromDataHolder}
                                brokerCode={state.brokerCode}
                                hasTokenUrl={hasTokenUrl}
                                setTheme={setTheme}
                            />
                        } />

                        <Route path={consentUiBasePath + "/preconsent"} element={
                            <>
                                {!state.loadedPreConsentConfig?.inclPreConsentStage && (
                                    <Navigate to={consentUiBasePath + "/consent/data-holder-selection"} />
                                )}
                                <ProductValueProposition
                                    basePath={consentUiBasePath}
                                    api={obApi}
                                    preConsentConfig={state.loadedPreConsentConfig}
                                    generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                    developerToolsConfig={state.loadedDeveloperTools}
                                    hasApiError={state.apiError}
                                    next={consentUiBasePath + "/consent/data-holder-selection"}
                                    principalLogoUrl={state.principalLogoUrl}
                                    headerBgImageUrl={state.headerBgImageUrl}
                                    pvpFeatureKeyVisualImageUrl={state.pvpFeatureKeyVisualImageUrl}
                                    pvpFeature1ImageUrl={state.pvpFeature1ImageUrl}
                                    pvpFeature2ImageUrl={state.pvpFeature2ImageUrl}
                                    pvpFeature3ImageUrl={state.pvpFeature3ImageUrl}
                                />
                            </>
                        } />

                        <Route path={consentUiBasePath + "/preconsent/cdr-value"} element={
                            <CDRValueProposition
                                prev={consentUiBasePath + "/preconsent"}
                                next={consentUiBasePath + "/consent/data-request"}
                                preConsentConfig={state.loadedPreConsentConfig}
                                generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                dashboardPath={dashboardPath}
                                isMultiBank={isMultiBank}
                                currentDataHolderSelection={state.dataHolderCurrentSelection}
                                api={obApi}
                                basePath={consentUiBasePath}
                                consentExists={state.existingPreConsentData?.consent_exists}
                                principalLogoUrl={state.principalLogoUrl}
                                accreditationIconUrl={state.accreditationIconUrl}
                                headerBgImageUrl={state.headerBgImageUrl}
                                cdrSupportingImageUrl={state.cdrSupportingImageUrl}
                                cdrFeature1ImageUrl={state.cdrFeature1ImageUrl}
                                cdrFeature2ImageUrl={state.cdrFeature2ImageUrl}
                                cdrFeature3ImageUrl={state.cdrFeature3ImageUrl}
                            />
                        } />

                        <Route path={consentUiBasePath + "/consent/data-request"} element={
                            <DataRequest
                                api={obApi}
                                prev={consentUiBasePath + "/preconsent/cdr-value"}
                                next={consentUiBasePath + "/consent/previous-data"}
                                basePath={consentUiBasePath}
                                sharingPeriodIsMandatory={true}
                                generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                onDeleteOptionChange={handleWithdrawConsentDeleteDataOption}
                                onConsentButtonClick={handleConsentButtonOnDataRequestScreen}
                                currentDeleteOption={state.currentWithdrawConsentDataDeleteOption}
                                consentConfig={state.loadedConsentConfig}
                                loadedPreConsentData={state.existingPreConsentData}
                                insightConfig={state.loadedInsightConfig}
                                dashboardPath={dashboardPath}
                                isMultiBank={isMultiBank}
                                consentExists={state.existingPreConsentData?.consent_exists}
                                preConsentConfig={state.loadedPreConsentConfig}
                                principalLogoUrl={state.principalLogoUrl}
                                accreditationIconUrl={state.accreditationIconUrl}
                                headerBgImageUrl={state.headerBgImageUrl}
                                currentDataHolderSelection={state.dataHolderCurrentSelection}
                            />
                        } />

                        <Route path={consentUiBasePath + "/consent/data-holder-selection"} element={
                            <DataHolderSelection
                                basePath={consentUiBasePath}
                                onSelectionChange={handleDataHolderSelectionChange}
                                haveAuthorisationWith={state.haveAuthorisationWith}
                                next={consentUiBasePath + "/preconsent/cdr-value"}
                                api={obApi}
                                preConsentConfig={state.loadedPreConsentConfig}
                                generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                principalLogoUrl={state.principalLogoUrl}
                                headerBgImageUrl={state.headerBgImageUrl}
                            />
                        } />

                        <Route path={consentUiBasePath + "/consent/previous-data"} element={
                            <PreviousDataDeleteConfirmation
                                next={consentUiBasePath + '/data-holder-connect-confirmation'}
                                prev={consentUiBasePath + "/consent/data-holder-selection"}
                                onCancel={() => navigate(consentUiBasePath + "/preconsent")}
                                onDeleteOptionChange={handleWithdrawConsentDeleteDataOption}
                                onIUnderstandButtonClick={handleIUnderstandButtonOnPreviousDataDeleteConfirmScreen}
                                currentDeleteOption={state.currentWithdrawConsentDataDeleteOption}
                                consentConfig={state.loadedConsentConfig}
                                loadedPreConsentData={state.existingPreConsentData}
                                generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                preconsentConfig={state.loadedPreConsentConfig}
                                api={obApi}
                                principalLogoUrl={state.principalLogoUrl}
                                headerBgImageUrl={state.headerBgImageUrl}
                                insightConfig={state.loadedInsightConfig}
                            />
                        } />

                        <Route path={consentUiBasePath + "/data-holder-connect-confirmation"} element={
                            <DataHolderConnectConfirmation
                                onAuthorisationAccept={handleAuthorisationAccept}
                                currentDataHolderSelection={state.dataHolderCurrentSelection}
                                next={consentUiBasePath + "/consent/success"}
                                basePath={consentUiBasePath}
                                api={obApi}
                                consentExists={state.existingPreConsentData?.consent_exists}
                                isMultiBank={isMultiBank}
                                loadedPreConsentData={state.existingPreConsentData}
                                preConsentConfig={state.loadedPreConsentConfig}
                                generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                principalLogoUrl={state.principalLogoUrl}
                                headerBgImageUrl={state.headerBgImageUrl}
                            />
                        } />

                        {(isMultiBank && !isConsentUpdateFromDashboard ?
                            <Route path={consentUiBasePath + "/consent/success"} element={
                                <>
                                    {isTrustedAdviserSession && (
                                        <Navigate to={trustedAdvisersBasePath + "consentui/consent/data-holder-selection"} />
                                    )}
                                    <MultiBankSelectionList
                                        basePath={consentUiBasePath}
                                        api={obApi}
                                        generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                        connectAnotherDataHolderPath={connectAnotherDataholderPath}
                                        currentDataHolderSelection={state.dataHolderCurrentSelection}
                                        onRedirectBack={handleCallBackFromDataHolder}
                                        next={consentUiBasePath + "/consent/confirmation"}
                                        principalLogoUrl={state.principalLogoUrl}
                                        headerBgImageUrl={state.headerBgImageUrl}
                                    />
                                </>
                            } /> :
                            <Route path={consentUiBasePath + "/consent/success"} element={
                                <>
                                    {isTrustedAdviserSession && (
                                        <Navigate to={trustedAdvisersBasePath + "consentui/consent/data-holder-selection"} />
                                    )}
                                    <Success
                                        basePath={consentUiBasePath}
                                        dashboardPath={dashboardPath}
                                        connectAnotherDataHolderPath={connectAnotherDataholderPath}
                                        currentDataHolderSelection={state.dataHolderCurrentSelection}
                                        onRedirectBack={handleCallBackFromDataHolder}
                                        generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                        consentConfig={state.loadedConsentConfig}
                                        api={obApi}
                                        principalLogoUrl={state.principalLogoUrl}
                                        headerBgImageUrl={state.headerBgImageUrl}
                                        loadPrincipalConfiguration={loadPrincipalConfiguration}
                                    />
                                </>
                            } />
                        )}

                        {isMultiBank &&
                            <Route path={consentUiBasePath + "/consent/confirmation"} element={
                                <MultibankSuccess
                                    api={obApi}
                                    generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                    principalLogoUrl={state.principalLogoUrl}
                                    headerBgImageUrl={state.headerBgImageUrl}
                                    insightConfig={state.loadedInsightConfig}
                                    consentConfig={state.loadedConsentConfig}
                                />
                            } />
                        }

                        <Route path={consentUiBasePath + "/consent/session-finished"} element={
                            <SessionFinished
                                api={obApi}
                                generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                principalLogoUrl={state.principalLogoUrl}
                                headerBgImageUrl={state.headerBgImageUrl}
                                insightConfig={state.loadedInsightConfig}
                                consentConfig={state.loadedConsentConfig}
                            />
                        } />

                        <Route path={consentUiBasePath + '/bank-feeds-request'} element={
                            <BankFeedsRequest
                                basePath={consentUiBasePath}
                                api={obApi}
                                isMultiBank={isMultiBank}
                                generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                principalLogoUrl={state.principalLogoUrl}
                                headerBgImageUrl={state.headerBgImageUrl}
                            />
                        } />

                        <Route path={consentUiBasePath + "/:token"} element={
                            <>
                                {(state.customerDetailsRequired ?
                                    <Navigate to={consentUiBasePath + "/customer-details"} /> : <Navigate to={consentUiBasePath + "/preconsent"} />)}
                                <CustomerDetailsInputs
                                    basePath={consentUiBasePath}
                                    api={obApi}
                                    next={consentUiBasePath + "/preconsent"}
                                    generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                    developerToolsConfig={state.loadedDeveloperTools}
                                    preConsentConfig={state.loadedPreConsentConfig}
                                    hasApiError={state.apiError}
                                    principalLogoUrl={state.principalLogoUrl}
                                    headerBgImageUrl={state.headerBgImageUrl}
                                />
                            </>
                        } />

                        <Route path={consentUiBasePath + "/customer-details"} element={
                            <CustomerDetailsInputs
                                basePath={consentUiBasePath}
                                api={obApi}
                                next={consentUiBasePath + "/preconsent"}
                                generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                developerToolsConfig={state.loadedDeveloperTools}
                                preConsentConfig={state.loadedPreConsentConfig}
                                hasApiError={state.apiError}
                                principalLogoUrl={state.principalLogoUrl}
                                headerBgImageUrl={state.headerBgImageUrl}
                            />
                        } />

                        <Route path={dashboardPath + "/consent-details/:id"} element={
                            <ConsentDetails
                                basePath={consentUiBasePath}
                                api={obApi}
                                dashboardPath={dashboardPath}
                                onDeleteOptionChange={handleWithdrawConsentDeleteDataOption}
                                currentDeleteOption={state.currentWithdrawConsentDataDeleteOption}
                                preconsentConfig={state.loadedPreConsentConfig}
                                consentConfig={state.loadedConsentConfig}
                                insightConfig={state.loadedInsightConfig}
                                loadedPreConsentData={state.existingPreConsentData}
                                generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                principalLogoUrl={state.principalLogoUrl}
                                headerBgImageUrl={state.headerBgImageUrl}
                                onSelectionChange={handleDataHolderSelectionChange}
                                loadPrincipalConfiguration={loadPrincipalConfiguration}
                            />
                        } />

                        <Route path={dashboardPath + "/consent-history/:id/*"} element={
                            <ConsentHistory
                                basePath={consentUiBasePath}
                                api={obApi}
                                dashboardPath={dashboardPath}
                                consentConfig={state.loadedConsentConfig}
                                loadedPreConsentData={state.existingPreConsentData}
                                generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                principalLogoUrl={state.principalLogoUrl}
                                headerBgImageUrl={state.headerBgImageUrl}
                                loadPrincipalConfiguration={loadPrincipalConfiguration}
                            />
                        } />

                        <Route path={dashboardPath + "/consent-details/:id/withdraw"} element={
                            <WithdrawConsent
                                basePath={consentUiBasePath}
                                api={obApi}
                                dashboardPath={dashboardPath}
                                onDeleteOptionChange={handleWithdrawConsentDeleteDataOption}
                                currentDeleteOption={state.currentWithdrawConsentDataDeleteOption}
                                preconsentConfig={state.loadedPreConsentConfig}
                                consentConfig={state.loadedConsentConfig}
                                generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                principalLogoUrl={state.principalLogoUrl}
                                headerBgImageUrl={state.headerBgImageUrl}
                                loadPrincipalConfiguration={loadPrincipalConfiguration}
                                insightConfig={state.loadedInsightConfig}
                            />
                        } />

                        <Route path={dashboardPath + "/consent-details/:id/withdraw-confirmation"} element={
                            <WithdrawConsentConfirmation
                                basePath={consentUiBasePath}
                                principalName={state.loadedGeneralSettingsConfig?.principalName}
                                api={obApi}
                                dashboardPath={dashboardPath}
                                generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                principalLogoUrl={state.principalLogoUrl}
                                headerBgImageUrl={state.headerBgImageUrl}
                                loadPrincipalConfiguration={loadPrincipalConfiguration}
                            />
                        } />

                        <Route path={dashboardPath + "/consent-details/:id/withdraw-success"} element={
                            <WithdrawConsentSuccess
                                basePath={consentUiBasePath}
                                api={obApi}
                                dashboardPath={dashboardPath}
                                preconsentConfig={state.loadedPreConsentConfig}
                                consentConfig={state.loadedConsentConfig}
                                generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                principalLogoUrl={state.principalLogoUrl}
                                headerBgImageUrl={state.headerBgImageUrl}
                                loadPrincipalConfiguration={loadPrincipalConfiguration}
                            />
                        } />

                        <Route path={dashboardPath} element={
                            <>
                                <Dashboard
                                    basePath={consentUiBasePath}
                                    api={obApi}
                                    connectDataHolderPath={connectAnotherDataholderPath}
                                    dashboardPath={dashboardPath}
                                    generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                    hasApiError={state.apiError}
                                    principalLogoUrl={state.principalLogoUrl}
                                    headerBgImageUrl={state.headerBgImageUrl}
                                    isAccount={Boolean(state.customerDetails?.account_id)}
                                    loadPrincipalConfiguration={loadPrincipalConfiguration}
                                    dashboardSettingsConfig={state.loadedDashboardSettingsConfig}
                                />
                            </>
                        } />

                        <Route path={dashboardPath + "/:token"} element={
                            <>
                                <Dashboard
                                    basePath={consentUiBasePath}
                                    api={obApi}
                                    connectDataHolderPath={connectAnotherDataholderPath}
                                    dashboardPath={dashboardPath}
                                    generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                    hasApiError={state.apiError}
                                    principalLogoUrl={state.principalLogoUrl}
                                    headerBgImageUrl={state.headerBgImageUrl}
                                    isAccount={Boolean(state.customerDetails?.account_id)}
                                    loadPrincipalConfiguration={loadPrincipalConfiguration}
                                    dashboardSettingsConfig={state.loadedDashboardSettingsConfig}
                                />
                            </>
                        } />
                        <Route path={"app/v1/data-delivery/:token"} element={
                            // TODO: stupid app/v1 prefix in the UI , needs to be centerlized in env or removed
                            <DataDelivery
                                obApi={obTrustedAdvisersApi}
                                basePath={consentUiBasePath}
                            />
                        } />

                        <Route path={apiAuthBasePath} element={
                            <ConsentApiAuthRedirect
                                api={obApi}
                                dataHolderBrand={state.validateTokenResponse?.data_holder_brand}
                                consentExists={state.validateTokenResponse?.consent_exists}
                                apiAuthBasePath={apiAuthBasePath}
                                basePath={consentUiBasePath}
                            />
                        } />

                        <Route path={apiAuthBasePath + "/:token"} element={
                            <ConsentApiAuthRedirect
                                api={obApi}
                                dataHolderBrand={state.validateTokenResponse?.data_holder_brand}
                                consentExists={state.validateTokenResponse?.consent_exists}
                                apiAuthBasePath={apiAuthBasePath}
                                basePath={consentUiBasePath}
                            />
                        } />

                        <Route path={"/session-expired"} element={
                            <div>
                                <SessionExpired
                                    basePath={consentUiBasePath}
                                    api={obApi}
                                    generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                    preConsentConfig={state.loadedPreConsentConfig}
                                    principalLogoUrl={state.principalLogoUrl}
                                    headerBgImageUrl={state.headerBgImageUrl}
                                />
                            </div>
                        } />

                        <Route path={"/unauthorised"} element={
                            <div>
                                <Unauthorised
                                    basePath={consentUiBasePath}
                                    api={obApi}
                                    generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                    preConsentConfig={state.loadedPreConsentConfig}
                                    principalLogoUrl={state.principalLogoUrl}
                                    headerBgImageUrl={state.headerBgImageUrl}
                                />
                            </div>
                        } />

                        <Route path={"/error"} element={
                            <div>
                                <Error
                                    basePath={consentUiBasePath}
                                    api={obApi}
                                    generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                    preConsentConfig={state.loadedPreConsentConfig}
                                    principalLogoUrl={state.principalLogoUrl}
                                    headerBgImageUrl={state.headerBgImageUrl}
                                />
                            </div>
                        } />

                        <Route path="*" element={
                            <NotFound
                                generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                preConsentConfig={state.loadedPreConsentConfig}
                                principalLogoUrl={state.principalLogoUrl}
                                headerBgImageUrl={state.headerBgImageUrl}
                            />
                        } />
                    </RouterSwitch>
                </ThemeProvider>
            </Provider>
        </>    
    );
}

export default withStyles(styles)(App);